import { Component, Input } from '@angular/core';
import { DomSanitizer } from '@angular/platform-browser';

@Component({
  selector: 'app-variant-icon',
  standalone: true,
  imports: [],
  templateUrl: './variant-icon.component.html',
  styleUrl: './variant-icon.component.scss'
})
export class VariantIconComponent {

  @Input() icon?: string = '';

  constructor(private sanitizer: DomSanitizer) {}

  get sanitizedIcon() {
    return this.sanitizer.bypassSecurityTrustHtml(this.icon || '');
  }
  
}
