<div [class.small]="size == 'small'">
    @if (price.priceVariant.icon) {
        <app-variant-icon class="icon" [icon]="price.priceVariant.icon.icon"></app-variant-icon>
    }

    @if (showName) {
        <span class="name">{{ menu | priceVariantTranslation:price.priceVariant.slugName }}: </span>
    }

    <span>{{ price.price ? price.price : '' }} {{ menu.currency?.symbol ?? currency }}</span>
</div>